.TilesGrid {
  header {
    max-width: calc(587rem / 16);
    margin: 0 auto clamp(20px, calc(48vw / 7.68), 64px);
    text-align: center;

    h2 {
      margin-bottom: clamp(16px, calc(28vw / 7.68), 28px);
    }
  }

  .wrapper {
    max-width: calc(834rem / 16);
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: clamp(24px, calc(32vw / 7.68), 48px) clamp(16px, calc(24vw / 7.68), 32px);

    .item {
      .img {
        display: block;
        margin-bottom: 24px;
        border-radius: 6px;
        overflow: hidden;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(180deg, rgba(253, 251, 248, 0.00) 0%, #FDFBF8 93.5%);
          z-index: 1;
        }

        .image {
          border-radius: inherit;
          transition: transform 0.4s var(--easing);
          aspect-ratio: 1/1;
          object-fit: cover;
          width: 100%;
          height: auto;
        }

        .iconWrapper {
          position: absolute;
          z-index: 2;
          display: grid;
          overflow: hidden;
          bottom: 0;
          left: 50%;
          transform: translate(-50%);

          >svg {
            height: 100%;
            width: 100%;
          }

          &[data-crocheting="true"] {
            bottom: clamp(15px, calc(25vw/7.68), 32px);
          }
        }
      }

      .cta {
        margin: 0 auto;
      }

      .img:hover,
      &:has(.cta:hover),
      &:has(.cta:focus-visible) {
        .image {
          transform: scale(1.05);
        }
      }
    }
  }

  position: relative;

  .decoration {
    position: absolute;
    left: 0;
    top: 0;
  }
}